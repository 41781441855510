import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Seo } from "../components/seo";
import Layout from "../components/layout";
import HireBanner from "../components/hire/HireBanner";
import OurWorkslef from "../components/hire/OurWorkself";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import Faq from "../components/Faq";
import { Helmet } from "react-helmet";

const HirePage = () => {
  const banner = {
    title: `Hire CRO Expert Consultants`,
    text: "Best Conversion Rate Optimisation Specialists",
    content: "Hire conversion rate optimization experts from Octet who can assist you in understanding your user behavior, discovering opportunities, and providing solutions to improve the overall conversion rate.",
    list: [
      'Top CRO Experts',
      'Flexible Hiring Options',
      'Time-Zone Friendly',
      'Optimization Guaranteed**'
    ]
  };
  const ourData = {
    title: "Proven Success with our CRO Consultants ",
    list1: [
      {
        logo: ['intellect.webp'],
        stars: 5,
        text: 'Octet has been very supportive to our design and technical requirements. Their designers and developers have always helped us to deliver creative and on-time solutions to our marketing needs.',
        name: 'Mariam Khan',
        des: 'Head Of Digital Marketing'
      },
      {
        logo: ['adani.webp'],
        stars: 5,
        text: "Octet Designers were instrumental in transforming our internal products and processes.",
        name: 'Naveen Kumar',
        des: 'QA Manager'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: "The team's flexibility and understanding of our business needs impressed us with their fast turnaround.",
        name: 'Ishan',
        des: 'Group Product Manager'
      }
    ],
    list2: [
      {
        logo: ['visa.webp'],
        stars: 5,
        text: 'With so many things we could be doing in our product, it was hard to streamline and decide where to invest our time and effort.',
        name: 'Vipin D',
        des: 'Customer Success Manager'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: 'Using Design first approach with Octet’s designers gave us more clarity and streamlined our solution and delivery.',
        name: 'Todd Baughman',
        des: 'SVP Global Operations'
      },
      {
        logo: ['intellect.webp'],
        stars: 5,
        text: 'They have delivered more than what they have committed. They made our sophisticated platform super simple and classic, our customers love using our app than any competitors.',
        name: 'Parag Chivate',
        des: 'Founder and CEO'
      },
    ]
  };
  const commonContent = {
    title: "Optimize Conversions with <span class='h1-span'>Our CRO Specialists</span>",
    content: [
      {
        title: "10+ Team of CRO Experts",
        text: "With a diverse team of 10+ CRO specialists, we bring deep expertise and a holistic perspective to meet your conversion optimization needs.",
      },
      {
        title: "250+ Clients Served",
        text: "With a track record of serving 250+ clients across industries, our website conversion experts deliver optimized solutions to drive maximum conversions.",
      },
      {
        title: "20k+ User Recordings Observed",
        text: "Having meticulously observed 20k+ recordings, our CRO experts understand user behavior and optimize user experience to drive conversions.",
      },
      {
        title: "300+  Landing Pages Optimised",
        text: "Our conversion optimization experts have optimized 300+ landing pages, ensuring that each page drives maximum conversion and results.",
      },
    ],
  };
  const commonContent1 = {
    title: "Hire CRO Experts to Drive Business Growth",
    content: [
      {
        title: "Discover Insights others miss",
        text: "Our CRO delves deep into uncovering hidden user insights and optimizes funnels to drive improvements in your conversions.",
      },
      {
        title: "Uncover conversion issues",
        text: "Our experts promptly identify shortcomings and employ rapid analysis techniques to enable timely resolutions and continuous landing page enhancements.",
      },
      {
        title: "Spot opportunities",
        text: "Our CRO pinpoints the areas of enhancement and applies continuous improvement strategies to improve task completion.",
      },
      {
        title: "Performs A/B testing",
        text: "Our conversion rate optimization consultants conduct personalized A/B with your users to gain insights and offer tailored solutions to meet their preferences.",
      },
      {
        title: "Validate your funnels with user data",
        text: "Our experts meticulously analyze user data to make decisions based on concrete evidence to ensure they align with user intent.",
      },
      {
        title: "Give strategic ideas & solutions",
        text: "Our CRO specialists tackle complex challenges with their expertise and bring solutions that deliver tangible results, fostering innovation.",
      },
    ],
  };
  const Questions = [
    "Which companies should hire conversion rate optimization expert?",
    "Why should I hire CRO experts from Octet?",
    "How do I hire conversion rate optimisation specialists from Octet?",
    "What is the source of communication with my CRO consultant?"
  ];
  const faqData = [
    {
      para: [
        `Various companies are now hiring CRO experts as competition and user expectations are growing daily.`,
        `This includes e-commerce businesses, SaaS companies, digital marketing agencies, financial services, travel and hospitality, healthcare providers, and any organization with a website or app aiming to improve conversions and user engagement.`
      ],
    },
    {
      para: [
        `Our CRO experts are the best at analyzing your website performance metrics, user behavior, and conversion funnels to spot opportunities for improvement.`,
        `They then create strategies to optimize design elements to increase your website visitors, who will take action based on your desired goals.`,
        `Hiring our conversion rate optimization experts will maximize the effectiveness of your website in achieving your business objectives.`
      ],
    },
    {
      para: [
        `You can hire our CRO experts in 3 easy steps:`,
      ],
      list: [
        `<span><h4 class="inline">Share Your Requirement:</h4></span> Please share your project needs and details with us, outlining your objectives and expectations, so that we can match you with the best-suited CRO specialist.`,
        `<span><h4 class="inline">Choose Your CRO Expert:</h4></span> Interview and hire CRO experts based on your business goals, assessing their experience, expertise, and compatibility.`,
        `<span><h4 class="inline">Get Started:</h4></span> Finalize and onboard your chosen conversion rate optimization expert and collaborate to implement effective optimization strategies and achieve your desired outcomes.`
      ]
    },
    {
      para: [
        `Once you onboard your hired CRO specialist, you can communicate with them through various channels such as email, phone calls, or video conferencing for real-time conversations and strategy sessions.`,
      ],
    },
  ];
  return (
    <>
    <Helmet>
      <script type="application/ld+json">
        {`
         {
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [{
            "@type": "Question",
            "name": "Which companies should hire conversion rate optimization expert?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Various companies are now hiring CRO experts as competition and user expectations are growing day by day.
        
        This includes e-commerce businesses, SaaS companies, digital marketing agencies, financial services, travel and hospitality, healthcare providers, and any organization with a website or app aiming to improve conversions and user engagement."
            }
          },{
            "@type": "Question",
            "name": "Why should I hire CRO experts from Octet?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Our CRO experts are the best at analyzing your website performance metrics, user behaviour and conversion funnels to spot opportunities for improvement.
        
        They then create strategies to optimize design elements to increase your website visitors who will take actions based on your desired goals.
        
        Hiring our conversion rate optimization experts will maximize the effectiveness of your website in achieving your business objectives."
            }
          },{
            "@type": "Question",
            "name": "How do I hire conversion rate optimisation specialists from Octet?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "You can hire our CRO experts in 3 easy steps:
        
        Share Your Requirement:
        Share your project needs and details with us, outlining your objectives and expectations so that we can provide you with the best-suited CRO specialist.
        
        Choose Your CRO Expert: 
        Interview and hire CRO experts based on your business goals, assessing their experience, expertise, and compatibility.
        
        Get Started:
        Finalize and onboard your chosen conversion rate optimization expert and commence collaboration to implement effective optimization strategies and achieve your desired outcomes."
            }
          },{
            "@type": "Question",
            "name": "What is the source of communication with my CRO consultant?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Once you onboard your hired CRO specialist, you can communicate with them through various channels such as email, phone calls, or video conferencing for real-time conversations and strategy sessions."
            }
          }]
        }                                        
        `}
      </script>
    </Helmet>
    <Layout hire="Hire Hotjar Research Analyst" hotjarHire={true} footerText="Maximize your conversions with Us">
      <div>
        <HireBanner data={banner} hire pageName="Hire Conversion Rate Optimisation" />
        <section className="py-[40px]">
          <div className="marquee lg:h-[100px] md:h-[80px] h-[60px]">
            <div className="lg:h-[100px] md:h-[80px] h-[60px]">
              <div className="flex items-center">
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                </div>
              </div>
              <div className="flex items-center">
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <OurWorkslef data={ourData} hire />
        <CommonServiceComponent data={commonContent} mode={"dark"} hire />
        <CommonServiceComponent data={commonContent1} mode={"light"} hire />
        <Faq grey section Questions={Questions} faqData={faqData} des={'Explore common questions about hiring CRO specialist to enhance your digital strategies and optimize your conversion rate effectively.'} />
      </div>
    </Layout>
    </>
  );
};

export default HirePage;

export const Head = () => (
  <Seo
    title="Hire CRO Experts and Specialists - Try for Free"
    description="Our CRO experts analyze user behavior and offer strategic solutions to improve your conversion rate. Hire our conversion rate optimization expert now!"
  />
);
